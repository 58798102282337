/*
 * Component: Hopper
 * ---------------
 */
.chevron-wrap {
  margin: 1em 0 2em;
}
.chevron {
  position: relative;
  text-align: center;
  padding: 5px;
  margin: 0 auto;
  width: 50%;
}
.chevron:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: skew(0deg, 6deg);
  -moz-transform: skew(0deg, 6deg);
  -ms-transform: skew(0deg, 6deg);
  -o-transform: skew(0deg, 6deg);
  transform: skew(0deg, 6deg);
}
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: skew(0deg, -6deg);
  -moz-transform: skew(0deg, -6deg);
  -ms-transform: skew(0deg, -6deg);
  -o-transform: skew(0deg, -6deg);
  transform: skew(0deg, -6deg);
}​
.fix-less{}
.chevron--green:before {
  background: @green;
}
.chevron--green:after {
  background: @green;
}
.chevron--yellow:before {
  background: @yellow;
}
.chevron--yellow:after {
  background: @yellow;
}
.chevron--red:before {
  background: @red;
}
.chevron--red:after {
  background: @red;
}
.chevron--small {
  height: 10px;
}
.chevron--medium {
  height: 30px;
}
.chevron--large {
  height: 60px;
}


/* Legend */
.legend {
  margin: 0;
  padding: 0;
}

.legend__item {
  display: inline-block;
  margin: 0.25em 0.25em 1em;
}

.tag {
  padding: 0.5em;
}