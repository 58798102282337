/*
 * Component: Form
 * ---------------
 */
.form-control {
  .border-radius(@input-radius);
  box-shadow: none;
  border-color: @gray;
  &:focus {
    border-color: @light-blue;
    box-shadow: none;
  }
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #bbb;
    opacity: 1;
  }

  &:not(select) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.form-group {
  position: relative;
  &--inline {
    position: relative;
    margin-bottom: 5px;
  }
  &--block-options {
    position: relative;
    margin-bottom: 5px;
    padding-right: 2em!important;
  }
  &--tight-bottom {
    margin-bottom: 10px;
  }
  &--tighter-bottom {
    margin-bottom: 6px;
  }
  &.has-success {
    label {
      color: @green;
    }
    .form-control {
      border-color: @green;
      box-shadow: none;
    }
  }

  &.has-warning {
    label {
      color: @yellow;
    }
    .form-control {
      border-color: @yellow;
      box-shadow: none;
    }
  }

  &.has-error {
    label {
      color: @red;
    }
    .form-control {
      border-color: @red;
      box-shadow: none;
    }
  }
}

/* Input group */
.input-group {
  .input-group-addon {
    .border-radius(@input-radius);
    border-color: @gray;
    background-color: #fff;
  }
}
/* button groups */
.btn-group-vertical {
  .btn {
    &.btn-flat:first-of-type, &.btn-flat:last-of-type {
      .border-radius(0);
    }
  }
}

.icheck > label {
  padding-left: 0;
}

/* support Font Awesome icons in form-control */
.form-control-feedback.fa {
    line-height: @input-height-base;
}

.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: @input-height-large;
}
.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: @input-height-small;
}

//Radio group
.radio-group {
  position: absolute;
  top: 0.125em;
  right: 0.125em;
}

.radio-group--block .iradio_flat-red {
  display: block!important;
}

/* Inline checkbox */
.checkbox--inline {
  position: absolute;
  top: -5px;
  right: 0.5em;
  z-index: 3;
}

.form__inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}