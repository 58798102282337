/*
 * Component: Label
 * ----------------
 */
.label-default {
  background-color: @gray;
  color: #444;
}
.label-danger {
  &:extend(.bg-red);
}
.label-info {
  &:extend(.bg-aqua);
}
.label-warning {
  &:extend(.bg-orange);
}
.label-primary {
  &:extend(.bg-light-blue);
}
.label-success {
  &:extend(.bg-green);
}
.label--large {
  font-size: 0.875em;
  line-height: 2;
}

.label-score {
  display: inline-block;
  line-height: 1.75;
  width: 1.75em;
  padding: 0;
}