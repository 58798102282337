.critiquer-input {
  @media (min-width: 1200px) {
    width: 17.692307692em
  }
}

/* Question - bg */
.question {
  background-color: @gray-light;
  padding: 0.125em;
}

.question--large {
  min-height: 3.35em;
}

.question label {
  font-weight: normal;
}

/* Player */
.player-controls {
  text-align: center;
  padding: 1em;
  background-color: #3d4eb8;
}

.media-player {
  .make-xs-column(6);
  .make-md-column(4);
}

.media-player-inner img {
  width: 100%;
}

.media-player.affix {
  .make-xs-column(10, @grid-gutter-width);
  z-index: 99;
  position: fixed;
  top: 0;

  .box.box-primary {
    border-top: none;
    border-radius: 0;
    border-bottom: 3px solid #3d4eb8;
  }

  .box-body {
    padding: 5px;
  }

  img.waveform {
    .make-xs-column(6);
    .make-md-column(4);
    height: 40px;
  }

  .player-controls {
    .make-xs-column(6);
    .make-md-column(4);
    margin: 0;
    padding: 5px;
    overflow: hidden;
    background-color: transparent;

    a
    {
      margin-right: 5px;
      float: left;
    }

  }

  .box-body {
    border: 0;
    border-radius: 0;
  }
  .box-header {
    display: none !important;
  }
}

.col-critiquer-label {
  @media (min-width: @screen-lg-min) {
    width: auto;
    text-align: right;
  }
}

.col-critiquer-block {
  @media (min-width: @screen-lg-min) {
    width: auto;
  }
}

.critiquer-label {
  @media (min-width: @screen-lg-min) {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.col-critiquer-input {
  @media (min-width: @screen-lg-min) {
    display: inline-block;
    width: 150px;
    vertical-align:middle;
  }
}
.col-critiquer-input--wide {
  @media (min-width: @screen-lg-min) {
    display: inline-block;
    width: 200px;
    vertical-align:middle;
  }
}
/* Scoring Label */
.scoring-label {
  padding-right: 3em;
  font-weight: normal;
}