/*
 * Component: Progress Bar
 * -----------------------
 */

//General CSS
.progress,
.progress > .progress-bar {
  .box-shadow(none);
  position: relative;
  overflow: visible;
  &, .progress-bar {
    .border-radius(@progress-bar-border-radius);
  }
}

/* size variation */
.progress.sm,
.progress-sm {
  height: 10px;
  &, .progress-bar {
    .border-radius(@progress-bar-sm-border-radius);
  }
}
.progress.xs,
.progress-xs {
  height: 7px;
  &, .progress-bar {
    .border-radius(@progress-bar-xs-border-radius);
  }
}
.progress.xxs,
.progress-xxs {
  height: 3px;
  &, .progress-bar {
    .border-radius(@progress-bar-xs-border-radius);
  }
}
/* Vertical bars */
.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
  > .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  //Sizes
  &.sm,
  &.progress-sm{
    width: 20px;
  }

  &.xs,
  &.progress-xs{
    width: 10px;
  }
  &.xxs,
  &.progress-xxs{
    width: 3px;
  }
}

//Progress Groups
.progress-group {
  display: block;
  .clearfix();

  .progress-text {
    font-weight: 600;
  }
  .progress-number {
    float: right;
  }
}

/* Remove margins from progress bars when put in a table */
.table {
  tr > td .progress {
    margin: 0;
  }
}

/* Progress with info */
.progress--with-info {
  margin-top: 36px;
}



/* Progress bar info */
.progress-bar__info {
  background-color: #3b444f;
  color: white;
  opacity: 0;
  padding: 3px 10px;
  position: absolute;
  text-align: center;
  left: 0;
  z-index: 40;
  opacity: 1;
  bottom: 160%;
  box-shadow: 0 1px 1px 2px rgba(3,46,92,0.12);
  border-radius: 5px;

  &:after {
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #3b444f;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 0;
    bottom: -6px;
    left: 0;
    top: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

/* Max & Min */
.progress-scale {
  margin-top: -20px;
  margin-bottom: 10px;
}

.progress-max {
  float: right;
}

// Variations
// -------------------------
.progress-bar-light-blue,
.progress-bar-primary {
  .progress-bar-variant(@light-blue);
}
.progress-bar-green,
.progress-bar-success {
  .progress-bar-variant(@green);
}

.progress-bar-aqua,
.progress-bar-info {
  .progress-bar-variant(@aqua);
}

.progress-bar-yellow,
.progress-bar-warning {
  .progress-bar-variant(@yellow);
}

.progress-bar-red,
.progress-bar-danger {
  .progress-bar-variant(@red);
}

.progress-bar-orange {
  .progress-bar-variant(@orange);
}
